import _ from 'underscore'
import $ from 'jquery'
import Backbone from 'backbone'
import { isHandheld } from 'util/breakpoints'
import { renderTemplate } from 'util/template_renderer'
import {
    getClientCompletionUrl,
    isInIFrame,
} from 'util/twistle'

import BackStack from "util/backstack"

// generic base class for dialog views
const DialogView = Backbone.View.extend({
    options: {
        hoverOffClose: null,
        title: "",
        autoOpen: false,
        modal: true,
        draggable: false,
        resizable: false,
        pushIfMobile: true,
        alwaysPush: false,
        fullyPreventDialog:false,
        zindex: 1101,
        hide: "fade",
        dialogClass: "dialog_panel"
    },
    events: function(){
        return {
            "click .detail_close_button":"hide", // only relevant when dialog is pushed onto stack in handheld mode
            "click .alert_close_button":"hide", // only relevant for generic alert dialogs
            "click .detail_next_button button":"handleNextButton" // only relevant when dialog is pushed onto stack in handheld mode
        };
    },
    initialize: function(options) {
        const self = this;
        self.options = _.extend({}, self.options, options);
        _.bindAll.apply(_, [self].concat(_.functions(self)));

        self.$titleButtons = [];

        if(self.options.fullyPreventDialog)
        {
            self.setTitle(self.options.title);
            return;
        }

        var pos = self.options.position || undefined;

        if(!pos)
        {
            self.options.centered = true;
        }


        // save the original user - specified class for the dialog
        self.options.originalDialogClass = self.options.dialogClass;

        // calculate the position of the dialog. It might append to the dialogClass
        // self is a separate private method, because you can reposition a dialog after it's been created
        // by passing "near" to reposition(...)
        self.options.position = self._calcPosition(pos);

        // for mobile, all dialogs are pushed on the stack
        if (App.stackNavigator && ((isHandheld() && self.options.pushIfMobile) || self.options.alwaysPush)) {
            if (App.stackNavigator.viewsStack.length === 0) {
                // nothing to go back to
                self.options.suppressBackButton = true;
            }

            self.$el.append(renderTemplate('stack_dialog_header_template', self.options)).addClass("stack_dialog");
            self.isOnStack = true;
            if(!self.options.pushImmediate) {
                App.stackNavigator.pushView(self, {}, self.options.pushAsFade ? new BackStack.FadeEffect() : new BackStack.SlideEffect());
            }
            else{
                App.stackNavigator.pushViewImmediate(self, {}, new BackStack.NoEffect());
            }
        }
        else
        {
            var $originalel = self.$el;

            if(!self.options.maxHeight)
            {
                self.options.maxHeight = $(window).height() - 150;
            }

            // instantiate the jquery dialog
            $originalel.dialog(self.options);

            self.dialogCreated = true;

            if(self.options.dialogClass.includes("tooltip_dialog") || self.options.noTitleBar) {
                $originalel.dialog("widget").find(".ui-dialog-titlebar").hide();
            } else if (self.options.title) {
                $originalel.dialog("widget").find(".ui-dialog-titlebar").addClass("bottom_border");
            }

            self.setElement($originalel.dialog("widget"));

            // hide the dialog during initial positioning
            $originalel.dialog("widget").css("visibility", "hidden");

            $originalel.bind("dialogopen", self.dialogOpen);

            // actually open the dialog
            $originalel.dialog("open");

            // deal w/ overlays
            if(self.options.modal){
                self.$overlay = $(".ui-widget-overlay").last();

                if(!self.options.trueModal){
                    self.$overlay.bind("click", self.hide);
                }
                if(self.options.overlayClass){
                    self.$overlay.addClass(self.options.overlayClass);
                }
                else{
                    // since we reuse overlays, need to set the default
                    self.$overlay.attr("class","ui-widget-overlay");
                }
            }

            $originalel.bind("dialogbeforeclose", self.dialogBeforeClose);
            $originalel.bind("dialogclose", self.close);
            if(self.options.hoverOffClose) {
                $originalel.bind("mouseleave", self.closeOnHover);
                $originalel.bind("mouseenter", self.cancelCloseOnHover);
                self.closeOnHoverTimer = null;
            }

            $originalel.dialog({
                dragStart: function() {
                    self.dragged = true;
                }
            });

        }

        // if the window changes, we change - binding to namespace scoped to this specific instance (cid)
        $(window).bind("resize.dialog-reposition-" + self.cid, self.onResize);

        self.tooRecentlyOpenedToClose = true;
        setTimeout(function(){
            self.tooRecentlyOpenedToClose = false;
        }, 450);

    },

    getEl:function()
    {
        if(this.options.fullyPreventDialog)
        {
            return this.$el;
        }
        return this.isOnStack ? this.$(".dialog_wrap") : this.$el.find(".ui-dialog-content");
    },

    getStackNavBar: function(){
        let self = this;
        if(self.isOnStack){
            return self.$(".detail_nav")
        }
    },

    onClose: function () {
        const self = this;
        $(window).unbind("resize.dialog-reposition-" + self.cid);
        // if this is in a frame, pass a message up
        if (isInIFrame()) {
            window.parent.postMessage("dialog_closed", "*");
        } else {
            let client_completion_url = getClientCompletionUrl();
            if (client_completion_url) {
                // redirect when the dialog closes to signal the action is complete
                window.location = client_completion_url;
            }
        }
    },

    getRoute:function()
    {
        return "dialog/" + new Date().getTime();
    },

    setTitle: function(title) {
        var self = this;
        if(self.options.fullyPreventDialog)
        {
            self.getEl().find(".prevented_dialog_title").text(title);
            return;
        }
        if(!self.isOnStack)
        {
            self.getEl().dialog("option", "title", title);
            self.getEl().dialog("widget").find(".ui-dialog-titlebar").show();
            self.getEl().dialog("widget").find(".ui-dialog-titlebar").addClass("bottom_border")
        }
        else
        {
            self.$(".detail_nav .subjectwrap").text(title);
        }
        self._setTitleButtonPos();
    },

    _setTitleButtonPos:function()
    {
        var self = this;
        if ((!self.isOnStack || self.options.titleButtonsOnStack) && !self.options.fullyPreventDialog &&
            self.$titleButtons?.length > 0)
        {
            var xPos;
            if (!self.isOnStack && !self.options.fullyPreventDialog) {
                xPos = self.getEl().dialog("widget").find(".ui-dialog-title").width();
            }
            else
            {
                // stack title button - could be a pull down title
                var $subject = self.$(".subjectwrap");
                xPos = $subject.offset().left + $subject.width();
            }
            _.each(self.$titleButtons, function($btn){
                if(!$btn.hasClass("fixed_pos")){
                    xPos += self.options.titleButtonSpacing || 35;
                    $btn.css("left", xPos);
                }
            });

        }
    },

    setTitleButton: function(className, text, handlerFn, tooltip) {
        var self = this, $altTitleBar = self.getEl().find(".prevented_dialog_title");
        if (self.options.fullyPreventDialog && $altTitleBar.length === 0)
        {
            return;
        }

        var $btn = $('<button class="bg-transparent border-none ' + className + '">' + (text || "") + '</button>');
        $btn.on('click', handlerFn);

        if(tooltip)
        {

            $btn.data("tooltip",tooltip).addClass("tt").addClass("tt-below");
        }

        if(!self.isOnStack) // goes next to title
        {
            if (!self.options.fullyPreventDialog) {
                self.getEl().dialog("widget").find(".ui-dialog-titlebar").append($btn);
            }
            else if ($altTitleBar.length > 0) {
                $altTitleBar.append($btn);
            }
        }
        else // is the right hand button
        {
             self.$(".detail_nav").append($btn);
        }
        self.$titleButtons.push($btn);
        self._setTitleButtonPos();
        return $btn;
    },

    destructionPolicy : "never",

    hide: function (keepAround) {
        var self = this;

        if(self.options.fullyPreventDialog)
        {
            return;
        }

        if(self.tooRecentlyOpenedToClose)
        {
            return;
        }

        if(this.onHide) {
            var hideResult = this.onHide();
            if(hideResult)
            {
                return false;
            }
        }

        if(self.isOnStack)
        {
            if(!self.poppedFromStack)
            {
                // make sure you can't do anything after close initiated
                self.undelegateEvents();

                if (!App.config.get("embeddedDialogMode")) {
                    // we change the back button into a spinner due to delays in rendering a base view. Not necessary on
                    // embedded dialog view mode.
                    self.$(".detail_close_button").addClass("closing").spin({color: "#FFFFFF", length: 5});
                }

                setTimeout(function(){
                    self.destructionPolicy = "default";
                    App.stackNavigator.popView(self.options.pushAsFade ? new BackStack.FadeEffect() : new BackStack.SlideEffect({direction:"right"}));
                    self.poppedFromStack = true;
                },20);
            }
        }
        else
        {
            if(self.dialogCreated && self.dialogIsOpen)
            {
                self.getEl().dialog("close");
                self.dialogCreated = false;
                self.dialogIsOpen = false;
            }

            if(!keepAround && self.options.modal){
                //set overlay back to default class
                self.$overlay.attr("class","ui-widget-overlay");
                self.close();
            }
        }
    },

    show: function() {
        var self = this;
        self.getEl().dialog("open");
    },


    handleNextButton: function(evt)
    {
        // basically a no-op - should be overridden by dialogs who set a nextButtonText
        // and are on the stack
        this.hide();
    },

    onResize: _.debounce(function (evt) {
        var self = this;
        if (!self.isViewClosing) {
            self.reposition(true);
        }
    }, 150, false),

    reposition: function(setWidth, near) {
        var self = this;

        if(self.options.onBeforeReposition)
        {
            self.options.onBeforeReposition(setWidth, near);
        }

        if(self.options.fullyPreventDialog)
        {
            return;
        }
        // only need to do stuff if the dialog is open and not dragged
        if ((!self.isOnStack || self.options.forceMobileOverlay) && self.getEl().data("ui-dialog") && !self.dragged){
            if(setWidth){
                self.getEl().dialog("option", "width", Math.min($(window).width(),self.options.width));
            }
            if(near){
                // recaluate the position of the dialog to be near another container
                // we reset the original dialog class, and clear any positional anchors
                self.options.dialogClass = self.options.originalDialogClass;
                self.options.positionNear = near;
                self.options.positionAbove = false;
                self.options.positionBelow = false;
                self.options.positionLeftOf = false;
                self.options.positionRightOf = false;
                self.getEl().dialog("option", "dialogClass", self.options.dialogClass);
            }
            if(near || self.options.centered)
            {
                // resposition relative to windoe
                self.options.position = self._calcPosition();
            }
            self.getEl().dialog("option", "position", self.options.position);
        }

        // always reset title button pos
        self._setTitleButtonPos();

        if(self.options.onAfterReposition)
        {
            self.options.onAfterReposition();
        }
    },

    dialogBeforeClose: function() {
        var self = this;
        self.getEl().css('opacity', 0);

        if (self.onBeforeClose){
            self.onBeforeClose();
        }
    },

    dialogOpen: function() {
        //this.$el.dialog("widget").position(this.options.position);
        var self = this;
        self.dialogIsOpen = true;
        setTimeout(
            function(){
                self.reposition();
                // show the dialog after initial positioning
                self.getEl().dialog("widget").css("visibility", "visible");
            },100);

    },

    closeOnHover: function() {
        var self = this;
        self.closeOnHoverTimer = setTimeout(function() {
            self.hide();
        }, self.options.hoverOffClose);
    },

    cancelCloseOnHover: function() {
        if(this.closeOnHoverTimer) {
            clearTimeout(this.closeOnHoverTimer);
            this.closeOnHoverTimer = null;
        }
    },

    _calcPosition: function(pos){
        if(this.options.positionNear) {
            // the idea here is that if the ref'd object to position against
            // is within half the width of the dialog away from the screen x edges,
            // or within 140px from the top/bottom edges, we position it in the opposite
            // direction
            var refOffset = this.options.positionNear.offset(), winWidth = $(window).width(), winHeight = $(window).height();

            if(refOffset.top < 140) {
                this.options.positionBelow = this.options.positionNear;
            }
            else if(refOffset.top > (winHeight - 140)) {
                this.options.positionAbove = this.options.positionNear;
            }
            else if(refOffset.left < (this.options.width / 2)) {
                this.options.positionRightOf = this.options.positionNear;
            }
            else if(refOffset.left > (winWidth - (winWidth / 2) - 100)) {
                this.options.positionLeftOf = this.options.positionNear;
            }
            else {
                this.options.positionRightOf = this.options.positionNear;
            }
            this.options.centered = false;
        }
        if(this.options.positionRightOf) {
            pos = {
                my: 'left' + (this.options.offsetX || '+20') + ' center' + (this.options.offsetY || ''),
                at: 'right center',
                of: this.options.positionRightOf
            };
            this.options.centered = false;
        }
        if(this.options.positionLeftOf) {
            pos = {
                my: 'left' + (this.options.offsetX || '-20') + ' center' + (this.options.offsetY || ''),
                at: 'left center',
                of: this.options.positionLeftOf
            };
            this.options.dialogClass += " " + "dialog_panel_left_of";
            this.options.centered = false;
        }
        if(this.options.positionAbove) {
            pos = {
                my: 'center' + (this.options.offsetX || '') + ' bottom' + (this.options.offsetY || '-20'),
                at: 'center top',
                of: this.options.positionAbove
            };
            this.options.dialogClass += " " + "dialog_panel_above";
            if (this.options.dialogClass.indexOf("above_right") > -1){
                pos.my = "right bottom";
                pos.at = "right top";
            }
            this.options.centered = false;
        }
        if(this.options.positionBelow) {
            pos = {
                my: 'center' + (this.options.offsetX || '') + ' top' + (this.options.offsetY || '+20'),
                at: 'center bottom',
                of: this.options.positionBelow
            };
            this.options.dialogClass += " " + "dialog_panel_below";
            this.options.centered = false;
        }
        if(this.options.positionBelowLeftOf) {
            pos = {
                my: 'right' + (this.options.offsetX || '') + ' top' + (this.options.offsetY || '+20'),
                at: 'right bottom',
                of: this.options.positionBelowLeftOf
            };
            this.options.dialogClass += " " + "dialog_panel_below";
            this.options.centered = false;
        }
        if(this.options.positionRightTop) {
            pos = { my: `left${this.options.offsetX || ''} top${this.options.offsetY || ''} `,
                    at: "right top",
                    of: this.options.positionRightTop
            };
            this.options.centered = false;
        }
        if(this.options.positionLeftTop) {
            pos = { my: `left${this.options.offsetX || ''} top${this.options.offsetY || ''} `,
                    at: "left top",
                    of: this.options.positionLeftTop
            };
            this.options.centered = false;
        }
        if(!pos || this.options.centered)
        {
            pos = { my: "center", at: "center", of: window };
        }

        pos.collision = this.options.collision || "fit";

        return pos;
    }
}, {});

export default DialogView;