import _ from 'underscore'
import Backbone from 'backbone'
import $ from 'jquery'
import {
    activateLinks,
    attachThumbSrc,
    extractContactAvatar,
    formatDate,
    formatNote,
    highlightSearchTerms,
    isRenderableVideoType,
    postRenderImages,
    splitParticipants,
    truncateFilename,
} from 'util/twistle'

import { localize } from 'util/localize'
import { isHandheld } from "util/breakpoints"
import { timeAgoInWords } from 'util/date'
import { escapeHTML } from "util/string"
import { renderTemplate } from 'util/template_renderer'
import Contact from 'app/models/contact'
import NoteParticipantStatusDialogView from 'app/views/note_participant_status_dialog_view'

// the conversation "dashboard - composed of multiple conversation lists and a filtering/arranging mechanism"
const ConversationDetailNotesView = Backbone.View.extend({
    className: "conversation_detail_notes_view",
    events: function() {
        var events = {
            "click .avatar-wrap":"authorClicked",
            "click .anote .meta_wrap": "noteMetaClicked",
            "click .anote .show_more_participants_in_message":"showMoreParticipantsClicked",
            "click .show_older_notes":"loadOlderNotes"
        };

        return events;
    },
    options: {

    },
    initialize: function() {
        var self = this;

        App.bind("app:response_height_changed", self.responseHeightChanged, self);
        App.bind("app:pending_note_removed", self.pendingNoteRemoved, self);
        App.bind("app:conversation_load_complete", self.conversation_load_complete, self);
        App.bind("app:form_submitted", self.handleFormSubmission, self);
        self.options.currentLocale = App.account.get("locale");

        // if viewing a live preview of a non-real conversation (from the workflow editor - prevent bound DOM events)
        if (self.model.isDummyData()){
            self.undelegateEvents();
        }

        self.render();
    },

    render: function() {
        var self = this;
        _.bindAll.apply(_, [self].concat(_.functions(self)));

        // when rendering as static output, make sure following options are on:
        if (App.config.get("isStaticOutput")) {
            self.options.fullTimestamps = true;
            self.options.showReadStatus = true;
            self.options.expandFormSubmissions = true;
        }

        self.addedNotes = [];
        self.submittedForms = [];
        self.addNotes(self.model.get("notes"));

        // if you requested this conversation by following a push notification, add a loading indicator
        if(self.model.loadFromPushNotification)
        {
            self.showLoadingIndicator();
        }

        setTimeout(function(){
            if(!self.options.preventInitialScroll){
                // normally - we auto scroll to the bottom of a conversation - except when told not to
                // e.g. because we are embedded into something else, like the workflow tracker
                self.scrollToBottom(true, true);
            }

            self.$el.on("scroll", self.notesAreaScrolled);
        },50);

        return self;
    },

    conversation_load_complete: function() {
        var self = this;
        self.hideLoadingIndicator();
        // see if we should switch orgs based on the loaded conversation
        var conversation_orgs = self.model.get("mapped_orgs")?.map(o => o.org_id);
        if (conversation_orgs?.length && !conversation_orgs.includes(App.account.getDefaultOrg().id)) {
            App.trigger("app:change_group", conversation_orgs);
        }
    },

    showLoadingIndicator: function(){
        var self = this;
        var $spin = $("<div></div>").addClass("conversation_loading_new_messages_indicator");
        $spin.appendTo(self.$el).spin({length:5,radius:3});
        return $spin;
    },


    hideLoadingIndicator: function(){
        var self = this;
        self.$(".conversation_loading_new_messages_indicator").remove();
    },

    responseHeightChanged: function(convId, newHeight)
    {
        var self = this;

        // only do this if it's our conversation (could be two active response areas at once)
        if(convId === self.model.get("id"))
        {
            self.$el.transition({bottom: newHeight}, 200, function () {
                if(self.isAtBottom) {
                    self.isAtBottom = false;
                    // when we change the response height, need to stay "stuck" at the bottom
                    self.scrollToBottom();
                }
            });
        }

    },

    scrollToBottom: function(orToMostRecentMatchedNote, orToFirstUnreadNote)
    {
        const self = this;
        let $scrollToNote = undefined;
        let $scrollableContainer = self.options.scrollWithinEl || self.$el;
        if(orToMostRecentMatchedNote && self.options.searchMatchedNotes)
        {
            var noteId = _.max(_.keys(self.options.searchMatchedNotes));
            $scrollToNote = self.$(".note-"+noteId);
        }

        if(orToFirstUnreadNote)
        {
            $scrollToNote = App.account.shouldScrollToFirstUnreadNote() ? self.$(".messagewrap.unread").first() : undefined;
        }

        if($scrollToNote?.length > 0){
            // scroll to the unread note, then an additional half-amount of the scrollable container's height
            const scrollOffset = $scrollableContainer.height() * 0.5;
            $scrollableContainer.scrollTo($scrollToNote, {offset: scrollOffset});
            return;
        }

        if(!self.isAtBottom){
            self.isAtBottom = true;
            $scrollableContainer.scrollToBottom();
        }

    },

    notesAreaScrolled: function(evt)
    {
        var self = this;
        if(self.$el.prop("scrollHeight"))
        {
            if((self.$el.prop("scrollHeight") - self.$el.height()) - 100 < self.$el.scrollTop())
            {
                self.isAtBottom = true;
            }
            else
            {
                self.isAtBottom = false;
            }
        }
    },

    addNotes: function(notes, isUpdate, byCurrentUser) {
        var self = this, didAddNote = false;

        var sortNumber = function(a,b)
        {
            return a - b;
        };

        self.addedNotes.sort(sortNumber);

        self.hideLoadingIndicator();

        if(byCurrentUser)
        {

            // if the user triggered this (e.g. by replying) - clear read indicators older than a few sec
            self.$(".conversation_note.unread .anote").each(function(idx, noteEl){
                var $note = $(noteEl);
                var noteRawDate = $note.data("date");
                const now = new Date();
                if(noteRawDate && now.getTime() - Date.parse(noteRawDate) > 3 * 1000)
                {
                    $note.parent().removeClass("unread");
                }
            });

            // and we are no longer "at the bottom" (which causes an auto-scroll)
            self.isAtBottom = false;
        }

        _.each(notes,function(note,idx){
            if(!note.is_private)
            {
                var noteData = self.augmentNote(note);
                var $noteUI = $(renderTemplate("conversation_detail_note_template", noteData, self.options.currentLocale));
                var noteId = parseInt(note.id,10), localNoteId = parseInt(note.localTempId,10);

                self.addAttachmentsToNote($noteUI, note.attachments, note.id);
                self.addAttachmentNotesToNote($noteUI, note.attachmentNotes);

                // adjust order of elements for printing (author / meta data goes above the message)
                if (App.config.get("isStaticOutput")) {
                    $noteUI.find(".meta_wrap").prependTo($noteUI.find(".anote"));
                }

                if(_.contains(self.addedNotes, localNoteId) || _.contains(self.addedNotes, noteId))
                {
                    // replace note UI if it is either pending hitting the server, or has attachmentNotes (which we only get after opening the conversation)
                    var noteUISelector =  ".pending.conversation_note_"+note.localTempId+",.pending.conversation_note_"+note.id;

                    if(note.attachmentNotes && note.attachmentNotes.length > 0)
                    {
                        noteUISelector += ",.conversation_note_"+note.id;
                    }

                    var $pending = self.$(noteUISelector);
                    if($pending.length > 0)
                    {
                        $pending.replaceWith($noteUI);
                    }

                }
                else
                {
                    self.addedNotes.push(noteId);
                    self.addedNotes.sort(sortNumber);
                    var noteIdx = _.indexOf(self.addedNotes, noteId);

                    if(noteIdx === 0)
                    {
                        $noteUI.prependTo(self.$el);
                    }
                    else if(noteIdx < self.addedNotes.length - 1 && !note.pending_post_to_server)
                    {
                        // if we are not at the end of the list
                        $noteUI.insertAfter(self.$(".conversation_note_" + self.addedNotes[noteIdx - 1]));
                    }
                    else
                    {
                        $noteUI.appendTo(self.$el);
                    }
                    didAddNote = true;
                }

                $noteUI.find(".anote").data(note);

                activateLinks($noteUI,
                    ".message_body",
                    self.model.get("convseq"),
                    note.id,
                    undefined,
                    self.model.getShortUrls(),
                    self.model.isDummyData(),
                    App.config.get("country_codes"));

            }
        });

        // should we display the "show older" indicator?
        // only do this on second pass of rendering notes - the first pass is whatever we have locally and we are likely
        // in the process of getting more from the server
        let shouldShowOlderIndicator =  parseInt(self.model.get("totalmessages"), 10) > (self.addedNotes.length || 1);
        if (self.model.get("notes").length > 1)
        {
            self.$(".show_older_notes").toggle(shouldShowOlderIndicator).prependTo(self.$el);
        }

        // scroll to bottom if new notes are added
        if(didAddNote && isUpdate && !self.isLoadingPrior)
        {
            self.scrollToBottom(false, true);
        }

        postRenderImages(self.$el);

    },


    loadOlderNotes: function(){
        var self = this;
        self.showLoadingIndicator().prependTo(self.$el);
        self.$(".show_older_notes").hide();
        self.isLoadingPrior = true;
        self.model.fetchOlder(function(r){
            self.addNotes(self.model.get("notes"),true);
            self.isLoadingPrior = false;
        });
    },

    pendingNoteRemoved:function(localTempId)
    {
        var self = this;
        self.$(".conversation_note_"+localTempId).remove();
    },

    augmentNote:function(note)
    {
        var self = this;
        var accounts = self.model.get('accounts');
        var authorInfo = self._getNoteAuthor(note.author);

        var fmtDate = self.options.fullTimestamps ? formatDate(note.date, undefined, "DEFAULT_24") : timeAgoInWords( note.date, isHandheld() );
        var message = self.highlightTerms(note.formattedSummary || formatNote(note.summary));
        var addressline = self.generateNoteAddressLine(note.author,
                                                       note.participants,
                                                       note.assignments,
                                                       note.unassignments,
                                                       note.participantstatus);

        var noteParticipants = splitParticipants(note.participants, false, note.author );
        var type = note.is_private ? 'sidebar' : 'normal';
        // we display the current note as authored by the current user if the visible author is them,
        // or it was sent as a role but they were the real / underlying author. The only exception is when
        // rending a live preview of a workflow step, which is allowed to populate this flag within the note's
        // data explicitly
        let authorIsCurrentUser = self.model.isDummyData() ? note.authorIsCurrentUser : (
            note.author === App.account.get("username") || note.original_author_id === App.account.get("username")
        );


        return {
            author: note.author,
            authorInfo: authorInfo,
            author_id: authorInfo.id,
            author_shortname: authorInfo.shortname,
            author_formalname: authorInfo.formalname,
            author_is_current_user: authorIsCurrentUser,
            addressline: addressline,
            avatar: extractContactAvatar(authorInfo),
            target: noteParticipants.target,
            message: message,
            type: type,
            indentation:0,
            statusmessage: (note.statusmessage && note.statusmessage.length > 0) ? formatNote(note.statusmessage) : undefined,
            date: fmtDate,
            rawdate: note.date,
            fulldate: formatDate(note.date),
            id: note.id,
            noteseq: note.noteseq,
            attachments: note.attachments,
            participantList: note.participants.replace(/\*/g, '').split(","),
            participants: note.participants,
            sortedParticipants: note.sortedParticipants,
            participantsStr: (noteParticipants.others.length>0)? ("also visible to "+ self.participantStatusString(note.participantstatus, note.author, noteParticipants.targets)):"",
            noteParticipants: noteParticipants,
            assignments: note.assignments,
            unassignments: note.unassignments,
            readflag: note.readflag,
            unreadclass: note.readflag === false && !self.options.hideUnreadIndicators ? "unread" : "",
            statusOnly: note.status_only ? "status_only" : "",
            pending: note.pending_post_to_server ? "pending" : ""
        };
    },

    _getNoteAuthor: function(authorUsername)
    {
        var self = this,
        accounts = self.model.get('accounts'),
        authorInfo = accounts[authorUsername] || {};

        if(!authorInfo.username && authorUsername === App.account.get("username"))
        {
            // I wrote this but I'm not (yet) a visible participant in the conversation
            authorInfo = App.account.toJSON();
        }
        return authorInfo;
    },

    addAttachmentsToNote: function(ui, attachments, noteId) {
        var self = this,
        merged = false;
        if(attachments.length > 0){
            var attachList = $('<div class="noteAttachmentDisplay"></div>');

            // did we match an attachment via search?
            if(self.options.searchMatchedNotes && _.has(self.options.searchMatchedNotes, noteId) && self.options.searchMatchedNotes[noteId] === "attach")
            {
                attachList.addClass("note_attach_matched_search");
            }

            //now insert each attachment
            _.each(attachments,function(attachment)
            {
                // workflow extended content gets jammed in inline
                if(attachment.workflow_extended_content?.length > 0){
                    ui.find(".message_body").append(`<div class='message_extended_content'>${attachment.workflow_extended_content}</div>`);
                    return;
                }

                var attach = $('<a class="fileElementNoteDisplay attach_thumb" data-type="'+attachment.type+'" data-seqnum="'+attachment.seqnum+'" data-size="prf"></a>');


                if(_.contains(["twistle/form-definition",
                        "twistle/form-submission"], attachment.type))
                {
                    // should we display this form "inline" ?
                    if (
                            (attachment.type === "twistle/form-submission" && self.options.expandFormSubmissions)
                            || attachment.show_inline
                    )
                    {
                        var formView = self.addChildView(new App.FormSubmissionDialogView(
                            {
                                fullyPreventDialog:true,
                                model:self.model,
                                inlineDisplay:true,
                                alwaysDisplayHeader: self.options.alwaysDisplayFormHeader,
                                imagesFullSize: self.options.expandFormSubmissions,
                                dummyPreviewFormData: (self.model.isDummyData()
                                    && self.model.get("dummyPreviewFormData")),
                                formId:attachment.form_definition,
                                submissionId:attachment.form_submission,
                                responseToNoteId:noteId,
                                onFormRendered: function(formData){
                                    self.inlineFormRendered(formData, noteId);
                                }
                            }));
                        formView.$el.appendTo(attachList);
                        ui.addClass("has_inline_form");
                        return;
                    }
                    else
                    {
                        attach.append('<span class="description">' + attachment.description + '</span>');
                        if (attachment.type === "twistle/form-submission") {
                            attach.addClass("twistle_form_submission_attachment");
                            attach.append('<span class="callout">' + localize("common-completed", "completed") + '</span>');
                        }
                        else {
                            attach.addClass("twistle_form_definition_attachment");
                            if (attachment.custom_form_button_text) {
                                attach.append(`<button class='tws-btn--primary'>${attachment.custom_form_button_text}</button>`);
                            } else {
                                attach.append(`<button class='tws-btn--primary'>${localize("CustomForm-fillOutForm", "Fill Out Form")}</button>`);
                            }
                        }
                        attach.data(attachment);
                    }
                }
                else {
                    const $img = $('<img/>', {
                        src: attachThumbSrc(attachment, "prf", undefined, ""),
                        css: {
                            "max-width": 200,
                            "max-height": 200,
                            "min-height": 72,
                        },
                    });
                    attach.append($img);
                    if (attachment.type.indexOf("image") !== 0 || !attachment.hasThumbnail) {
                        attach.append('<span>' + truncateFilename(attachment.description) + '</span>');
                    }
                }

                if (isRenderableVideoType(attachment.type)) {
                    attach.addClass("attach_video_element");
                }

                attachment.noteId = noteId;

                attach.click(attachment,self.loadAttachment);

                attach.hoverClass("fileElementNoteDisplayHover");
                attachList.append(attach);
            });
            attachList.append('<div class="noteAttachmentClear clearer"></div>');
            if(merged){
                attachList.appendTo(ui);
            }else {
                attachList.appendTo(ui.find(".message_attach_wrap"));
            }
            ui.addClass("has_attachments");
        }
    },

    addAttachmentNotesToNote: function(ui, attachmentNotes, merged) {
        var self = this;
        if(merged === undefined){
            merged = false;
        }
        if(attachmentNotes && attachmentNotes.length > 0){
            var noteList = $('<div class="noteAttachmentNotesDisplay"></div>');
            _.each(attachmentNotes, function(attNote, idx){
                var note = self.augmentNote(attNote);
                note.isAttachedNote = true;
                note.indentation+=1;

                var $noteUI = $(renderTemplate("conversation_detail_note_template", note, self.options.currentLocale));

                self.addAttachmentsToNote($noteUI, note.attachments, note.id);

                $noteUI.find(".anote").data(note);

                if(idx > 0){
                    noteList.append('<div class="fnote-divider"></div>');
                }

                $noteUI.appendTo(noteList);

            });
            noteList.append('<div class="noteAttachmentClear clearer"></div>');
            if(merged){
                noteList.appendTo(ui.find(".mergedMessage"));
            }else {
                noteList.appendTo(ui.find(".message"));
            }
            ui.addClass("has_attachmentNotes");
        }
    },

    generateNoteAddressLine: function(author,participants,assignments,unassignments,participantstatus){
        var self = this;
		var noteParticipants = splitParticipants(participants, false, author );
		var accounts = self.model.get('accounts');
        var authorInfo = self._getNoteAuthor(author)
        var authorText = escapeHTML(self.highlightTerms((authorInfo && authorInfo.formalname) || "Unknown"));
        var targetText = "";
        if(noteParticipants.targets.length > 0){
            targetText += "<br/> @ ";
            $.each(noteParticipants.targets,
                function(idx,val){

                    var isRead = participantstatus && participantstatus[val],
                        className =  isRead ? "statusRead tt tt-above" : "statusUnread";

                    var tt = "";
                    // status for thyself is irrelevant
                    if(val === App.account.get("username"))
                    {
                        className = "";
                    }

                    if(idx > 2){
                        className += " hidden";
                    }

                    if(participantstatus && participantstatus[val])
                    {
                        tt = "Read " + timeAgoInWords(participantstatus[val]);
                    }

                    targetText += `<span class="${className}" data-tooltip="${tt}">`;

                    const highlighted = self.highlightTerms(accounts[val] && accounts[val].formalname)
                    if (highlighted) {
                        targetText += escapeHTML(highlighted);
                    }

                    if(isRead)
                    {
                        if(self.options.fullTimestamps)
                        {
                            targetText +=  '<span class="read_date">(viewed ' + formatDate(participantstatus[val], undefined, "DEFAULT_24") + ')</span>';
                        }
                        else
                        {
                            targetText +=  '<span class="read_check"></span>';
                        }
                    }

                    if(noteParticipants.targets.length > 1 && idx < noteParticipants.targets.length-1){
                        targetText += ", ";
                    }
                    targetText += "</span>";
                    if(idx === 2 && noteParticipants.targets.length > 3){
                        targetText +=  " <span class='show_more_participants_in_message'>+" + (noteParticipants.targets.length-3) + " more</span>";
                    }
                }
            );
        }

        return authorText + targetText;

    },

    participantStatusString: function(statusHash, author, targets) {
        var returnString = "";
        var pcount = 0;
        _.each(statusHash, function(participant){
            if(participant === author || !_.include(targets,participant)) {
                return true;
            }
            var participantOutput = escapeHTML(participant);
            var addString = (pcount > 0 ? ", " : "") + (statusHash[participant] ? "<span title= 'note read by " + participantOutput + "' class=statusRead>" : "<span title= 'note unread by " + participantOutput + "' class=statusUnread>") + participantOutput + "</span>";
            returnString += addString;
            pcount++;
        });
        return returnString;
    },


    highlightTerms: function(text){
        var self = this;
        return highlightSearchTerms(text, self.options.searchTerms);
    },

    inlineFormRendered: function(formData, noteId){
        let self = this;
        if(formData.submission && self.isAtBottom){
            // force re-scroll as our content height just grew
            self.isAtBottom = false;
            self.scrollToBottom(true, true);
        }
    },

    authorClicked: function(evt)
    {
        var self = this;
        var $author = $(evt.currentTarget);
        App.logClickstream('conversationDetailAuthorAvatarClickedToShowContactDetail');
        if($author.data("username")){
            App.trigger("app:view_contact", new Contact({
                username: $author.data("username")
            }));
        }
        evt.stopPropagation();
    },

    noteMetaClicked: function (evt)
    {
        var self = this;
        var $note = $(evt.currentTarget),
        noteId = $note.data("id");
        App.openDialogView(new NoteParticipantStatusDialogView(
            {
                model:self.model,
                noteId:noteId
            }
        ));
    },

    showMoreParticipantsClicked:function(evt){
            App.logClickstream('conversationnoteShowMoreRecipientsClicked');
            var showMoreLink = $(evt.currentTarget);
            showMoreLink.parent().find(".hidden").show("fade");
            showMoreLink.hide();
            evt.stopPropagation();
    },

    loadAttachment: function(evt) {
        var self = this;
        self.loadAttachmentFromData(evt && evt.data);
        if(evt) {
            evt.stopPropagation();
        }
    },

    loadAttachmentFromData : function(data)
    {
        var self = this;
        if(data.type === "twistle/form-definition" || data.type === "twistle/form-submission" )
        {
            let options = {};
            if (App.account.isProvider()) {
                if(self.openProviderFormNoteId === data.noteId){
                    // form already open in provider view - do nothing
                    return;
                }
                self.openProviderFormNoteId = data.noteId;
                options = {
                    modal: false,
                    draggable: true,
                    onCloseCallback: function(){
                        self.openProviderFormNoteId = undefined;
                    }
                };
            }
            App.openDialogView(new App.FormSubmissionDialogView({
                model: self.model,
                formId: data.form_definition,
                submissionId: data.form_submission,
                responseToNoteId: self.model.isDummyData() ? null : data.noteId,
                readOnly: self.model.isDummyData(),
                asTemplate: self.model.isDummyData(),
                ...options
            }));
        }
        else if(data.type === "twistle/workflow-definition")
        {
            App.openDialogView(new App.WorkflowDefinitionDialogView({model:self.model,
                                                          workflowDefId:data.workflow_definition}));
        }
        else
        {
            App.trigger("app:show_attachment", data.seqnum, data);
        }

    },


    handleFormSubmission: function(formDef, submitResponse, convseq, responseToNoteId){
        var self = this;
        self.submittedForms.push(formDef.id);
        if(self.model.get("convseq") === convseq)
        {
            self.$(".note-" + responseToNoteId + " .fileElementNoteDisplay").each(function(idx,el){
                var $el = $(el), attachData = $el.data();
                if(attachData.type === "twistle/form-definition")
                {
                    if(!_.contains(self.submittedForms, attachData.form_definition))
                    {
                        // ok, open the next form unless it's already been submitted
                        setTimeout(function(){
                            self.loadAttachmentFromData(_.extend({noteId:responseToNoteId}, attachData));
                        },100);
                        return false; // stop looping
                    }
                }
            });

            // make sure all notes in the conversation older than this one are marked as read
            self.$(".conversation_note.unread .anote").each(function(idx, noteEl){
                let $note = $(noteEl);
                let noteId = $note.data("id");
                if(parseInt(noteId, 10) <= parseInt(responseToNoteId, 10)){
                    $note.parent().removeClass("unread");
                }
            });

        }
    }
});

export default ConversationDetailNotesView;